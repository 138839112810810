body {
  touch-action: pan-x pan-y;
}

body,
html {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #77e5bd;

  min-height: 100vh;
}

html::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100vh;
}

@font-face {
  font-weight: 300;
  font-family: Roboto;
  src: url('./assets/fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-weight: 400;
  font-family: Roboto;
  src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-weight: 700;
  font-family: Roboto;
  src: url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-weight: 500;
  font-family: Roboto;
  src: url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-weight: 400;
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
}

.recurly-element,
.recurly-hosted-field {
  height: 19.5px !important;
}

.berkeley-3ds {
  position: absolute;
  z-index: 10000;
  width: 100%;
  max-width: 1200px;
  min-height: 500px;
  top: 130px;
  border: 0;
  left: 50%;
  transform: translateX(-50%)
}
.ooMain {
  touch-action: pan-x pan-y !important;
}
.ooMain * {
  touch-action: pan-x pan-y !important;
}

.ooCaptureHeader {
  padding-top: 64px !important;
}
.ooInputListContent-input {
  font-size: 16px !important;
}